@import '@/styles/variables';

.searchPlace {
  //position: relative;
  width: 100%;

  .search_place_wrapper {
    position: relative;
    transition: top 1s ease-in-out;
  }

  &_geoIcon {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
    z-index: 1;
    background: none;
    border: none;
    font-size: 20px;
    color: $color-gray-dark;
    cursor: pointer;
    line-height: 0;

    &:hover {
      color: $color-black;
    }
  }

  &_select {
    width: 100% !important;
  }

  &_input {
    font-size: $font-size-base;
    line-height: $font-size-xxl;
    padding: $spacing-3 36px;
    background: #fff;
    border: $border-width-base solid $color-gray;
    border-radius: $border-radius-base;
    width: 100%;
    overflow: hidden;

    &:focus {
      outline: none;
      box-shadow: $shadow;
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
    }
  }

  &_dropdown {
    min-height: 40px;
    z-index: $z-index-header-dropdown;
    border: $border-width-base solid $color-gray;
    border-bottom-right-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
    box-shadow: $shadow;
  }

  &_clearIcon {
    position: absolute;
    right: 1px;
    top: 1px;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 38px;
    align-items: center;
    font-size: 20px;
    color: $color-gray-dark;
    cursor: pointer;
    line-height: 0;
    border: none;
    background-color: unset;

    svg {
      transition:
              transform 0.2s ease-in-out,
              background-color 0.2s ease-in-out;
    }

    &:hover {
      color: $color-black;

      svg {
        transform: scale(1.15);
      }
    }

    &__isMainPage {
      font-size: 25px;
      border-radius: unset;
      top: 1px;
      right: 50px;
      height: 66px;
      width: 50px;
      border-right: 1px solid $color-gray;
    }
  }

  &__isMainPage {
    .searchPlace_forwardIcon,
    .searchPlace_geoIcon {
      height: 66px;
      width: 50px;
    }

    .searchPlace_forwardIcon {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: $color-black-op-05;
      cursor: pointer;
      line-height: 0;
      padding: 0;
      z-index: 10;

      svg {
        width: 24px;
        height: 24px;
        font-size: 24px;
        transition:
                transform 0.2s ease-in-out,
                background-color 0.2s ease-in-out;
      }

      &:hover {
        color: $color-black;

        svg {
          transform: scale(1.15);
        }
      }

      &:hover {
        color: $color-black;
        background-color: transparent;
      }
    }
  }

  &__square {
    .searchPlace_clearIcon,
    .searchPlace_geoIcon {
      height: 48px;
    }
  }
}



